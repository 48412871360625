import React from 'react'
import { Switch, Route } from 'react-router-dom'

import Dashboard from './pages/DashboardHome/Dashboard.page'
import PropertiesPage from './pages/DashboardProperties/Properties.page'
import TenantsPage from './pages/TenantsPage/TenantsPage'
import TenantSummary from './pages/TenantsPage/TenantSummary/TenantSummary'

import './App.css'

function App() {
  return (
    <div className='App'>
      <Switch>
        <Route path='/properties' component={PropertiesPage} />
        <Route path='/tenants' component={TenantsPage} />
        <Route path='/tenant-summary' component={TenantSummary} />
        <Route path='/' component={Dashboard} />
      </Switch>
    </div>
  )
}

export default App
