import React, { useState } from 'react'
import Layout from '../../components/Layout/Layout'
import Button from '../../components/Button/Button'

import { PROPERTIES_DEMO_DATA } from '../../services/PropertiesData'
import PropertiesCard from '../../components/PropertiesCard/PropertiesCard'

import './PropertiesPage.css'
import FilterTabs from '../../components/FilterTabs/FilterTabs'

const PropertiesPage = () => {
  const [filterActive, setFilterActive] = useState(1)

  const filterArray = [
    {
      id: 1,
      value: 'All Active Properties',
    },
    {
      id: 2,
      value: 'Multi-Family Buildings',
    },
    {
      id: 3,
      value: 'Archived Properties',
    },
  ]

  return (
    <Layout>
      <div className='properties-heading-section'>
        <h1 className='properties-heading'>
          County Line Corporation Properties
        </h1>
        <div>
          <Button value='Add Property' variant='primary' icon='+' size='big' />
        </div>
      </div>

      <div className='properties-filter-section'>
        <FilterTabs
          filterArray={filterArray}
          filterActive={filterActive}
          setFilterActive={setFilterActive}
        />
      </div>

      <div className='properties-cards-section'>
        {PROPERTIES_DEMO_DATA.map((item) => (
          <div className='properties-single-card' key={item.key}>
            <PropertiesCard
              name={item.name}
              address={item.address}
              totalUnit={item.totalUnit}
              occupiedUnit={item.occupiedPercentage}
              vacantUnit={item.vacantUnit}
              occupiedPercentage={item.occupiedPercentage}
            />
          </div>
        ))}
      </div>
    </Layout>
  )
}

export default PropertiesPage
