import SummaryIcon from '../assets/icons/tenants/summary/summary-icon.svg'
import EditIcon from '../assets/icons/tenants/summary/edit-icon.svg'
import FinancialsIcon from '../assets/icons/tenants/summary/financials-icon.svg'
import DocumentsIcon from '../assets/icons/tenants/summary/documents-icon.svg'
import CommunicationIcon from '../assets/icons/tenants/summary/communication-icon.svg'

export const FILTER_MENU_ITEMS = [
  {
    id: 1,
    title: 'Summary',
    subTitle: 'Overview of Tenant',
    icon: SummaryIcon,
  },
  {
    id: 2,
    title: 'Edit info',
    subTitle: 'Make changes to Tenants info',
    icon: EditIcon,
  },
  {
    id: 3,
    title: 'Financials',
    subTitle: 'Tenant Ledger',
    icon: FinancialsIcon,
  },
  {
    id: 4,
    title: 'Documents',
    subTitle: 'View & Upload Tenant Docs',
    icon: DocumentsIcon,
  },
  {
    id: 5,
    title: 'Communication',
    subTitle: 'View Conversation with Tenant',
    icon: CommunicationIcon,
  },
]

export const TENANT_SUMMARY_PERSON_DATA = {
  name: 'Salman Nyenonbai',
  honorific: '(Mr.)',
  email: 's.ferguson@gmail.com',
  email2: 's.ferguson2@gmail.com',
  phoneM: '+1 541 754 3010',
  phoneH: '+1 541 754 2658',
  phoneW: '+1 541 953 3086',
  address: '216-218 Bloomfield 1A',
  houseAddress: '216-218 Bloomfield 1 Ave.',
  townAddress: 'Newark, NJ 08701',
  profilePic: 'SN',
  dateOfBirth: '10/23/1989',
  socialSecurityNumber: 'xxx-xx-4865',
  occupation: 'Real Estate Broker',
  employer: 'Remax',
  contactName: 'Salman Nyenobai',
  contactNumber: '+1 541 754 2658',
  contactEmail: 's.ferguson2@gmail.com',
  activeLeaseAdress: '216-218 Bloomfield- 1A',
  activeLeaseTime: '8/1/2019 - 8/31/2020',
  activeTerm: 'Fixed Term',
  netRent: '$1,1000.00',
  requiredDeposit: '$1,1000.00',
  depositeInHand: '$0.00',
  balance: '2,200.00',
}
