import React, { useState } from 'react'

import EditPencil from '../../assets/icons/tenants/Editpencil.svg'

import './TenantsTable.css'

const TenantsTable = ({ tableHead, tableColumns }) => {
  const [isActive, setActive] = useState([])

  return (
    <table className='tenants-table'>
      <thead className='table-head'>
        <tr className='table-head-item-wrapper'>
          {tableHead.map((item) => (
            <th className='table-head-item' key={item.id}>
              {item.value}
            </th>
          ))}
        </tr>
      </thead>

      <tbody>
        {tableColumns.map((item) => (
          <tr
            key={item.id}
            className={
              isActive.indexOf(item.id) !== -1
                ? 'table-column-item-wrapper column-active'
                : 'table-column-item-wrapper'
            }
          >
            <td className='table-column-item'>
              <input
                type='checkbox'
                defaultChecked={isActive.indexOf(item.id) !== -1 ? 1 : 0}
                onChange={() => {
                  isActive.indexOf(item.id) !== -1
                    ? setActive(
                        isActive.filter((element) => element !== item.id)
                      )
                    : setActive([...isActive, item.id])
                }}
              />
            </td>
            <td className='table-column-item'>{item.name}</td>
            <td className='table-column-item'>{item.property}</td>
            <td className='table-column-item'>{item.unit}</td>
            <td className='table-column-item'>{item.balance}</td>
            <td className='table-column-item'>{item.email}</td>
            <td className='table-column-item'>{item.phone}</td>
            <td className='table-column-item'>{item.tenancy}</td>
            <td className='table-column-item'>
              <div className={`lease-status-${item.leaseStatus}`}>
                {item.leaseStatus}
              </div>
            </td>
            <td className='table-column-item'>
              {item.action === 'edit' ? (
                <img src={EditPencil} alt={item.action} />
              ) : undefined}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default TenantsTable
