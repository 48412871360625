export const PROPERTIES_DEMO_DATA = [
	{
		key: 1,
		name: '213-216 Bloomfield Ave',
		address: 'Newark, NJ 07014',
		totalUnit: 24,
		occupiedUnit: 19,
		vacantUnit: 5,
		occupiedPercentage: 88,
	},
	{
		key: 2,
		name: '125 PropertEase Blvd',
		address: 'Newark, NJ 07014',
		totalUnit: 24,
		occupiedUnit: 19,
		vacantUnit: 5,
		occupiedPercentage: 88,
	},
	{
		key: 3,
		name: '930 East County Line Road',
		address: 'Newark, NJ 07014',
		totalUnit: 24,
		occupiedUnit: 19,
		vacantUnit: 5,
		occupiedPercentage: 88,
	},
	{
		key: 4,
		name: '213-216 Bloomfield Ave',
		address: 'Newark, NJ 07014',
		totalUnit: 24,
		occupiedUnit: 19,
		vacantUnit: 5,
		occupiedPercentage: 88,
	},
	{
		key: 5,
		name: '125 PropertEase Blvd',
		address: 'Newark, NJ 07014',
		totalUnit: 24,
		occupiedUnit: 19,
		vacantUnit: 5,
		occupiedPercentage: 88,
	},
	{
		key: 6,
		name: '930 East County Line Road',
		address: 'Newark, NJ 07014',
		totalUnit: 24,
		occupiedUnit: 19,
		vacantUnit: 5,
		occupiedPercentage: 88,
	},
	{
		key: 7,
		name: '213-216 Bloomfield Ave',
		address: 'Newark, NJ 07014',
		totalUnit: 24,
		occupiedUnit: 19,
		vacantUnit: 5,
		occupiedPercentage: 88,
	},
	{
		key: 8,
		name: '125 PropertEase Blvd',
		address: 'Newark, NJ 07014',
		totalUnit: 24,
		occupiedUnit: 19,
		vacantUnit: 5,
		occupiedPercentage: 88,
	},
	{
		key: 9,
		name: '930 East County Line Road',
		address: 'Newark, NJ 07014',
		totalUnit: 24,
		occupiedUnit: 19,
		vacantUnit: 5,
		occupiedPercentage: 88,
	},
]
