import React from 'react'

import SearchIcon from '../../assets/icons/navbar/search.svg'
import DownArrow from '../../assets/icons/navbar/downArrow.svg'
import Notification from '../../assets/icons/navbar/notification.svg'
import NotificationWithRed from '../../assets/icons/navbar/notificationsWithRed.svg'

import './Navbar.css'

const Navbar = () => {
	const notification = true

	return (
		<div className='navbar'>
			<div className='navbar-left'>
				<img
					src={SearchIcon}
					alt='search-icon'
					className='navbar-search-icon'
				/>
				<input type='text' className='navbar-search' placeholder='Search' />
			</div>

			<div className='navbar-right'>
				<div className='navbar-profile'>
					<div className='profile-picture'>ES</div>
					<div className='profile-details'>
						<div className='profile-name'>Eric Soloff</div>
						<div className='profile-designation'>Owner</div>
					</div>
					<img
						src={DownArrow}
						alt='setting-icons'
						className='profile-setting-icon'
					/>
				</div>
				<div className='navbar-notification'>
					{notification ? (
						<img src={NotificationWithRed} alt='notification' />
					) : (
						<img src={Notification} alt='notification' />
					)}
				</div>
			</div>
		</div>
	)
}

export default Navbar
