import React, { useState } from 'react'

import Layout from '../../components/Layout/Layout'
import Button from '../../components/Button/Button'
import FilterTabs from '../../components/FilterTabs/FilterTabs'
import DropDownButton from '../../components/DropDownButton/DropDownButton'
import TenantsTable from '../../components/Table/TenantsTable'
import {
  TENANTS_TABLE_DATA,
  TENANTS_TABLE_HEAD,
} from '../../services/TenantsData'

import DownButton from '../../assets/icons/tenants/DownIcon.svg'

import './TenantsPage.css'
import ProspectsTable from '../../components/Table/ProspectsTable'
import {
  PROSPECTS_TABLE_DATA,
  PROSPECTS_TABLE_HEAD,
} from '../../services/ProspectsData'

const TenantsPage = () => {
  const [filterActive, setFilterActive] = useState(1)

  const filterArray = [
    {
      id: 1,
      value: 'Tenants',
    },
    {
      id: 2,
      value: 'Prospects',
    },
  ]

  return (
    <Layout>
      <div className='tenants-top-part'>
        <FilterTabs
          filterArray={filterArray}
          filterActive={filterActive}
          setFilterActive={setFilterActive}
        />

        {filterActive && filterActive === 1 ? (
          <div className='top-buttons'>
            <div className='margin-right-2'>
              <Button value='Collect Rent' variant='ghost' size='small' />
            </div>

            <div className='margin-right-2'>
              <Button value='Send Message' variant='ghost' size='small' />
            </div>
            <Button
              value='Add Property'
              variant='primary'
              icon='+'
              size='big'
            />
          </div>
        ) : (
          <div className='top-buttons'>
            <div className='margin-right-2'>
              <Button value='Run Screening' variant='ghost' size='small' />
            </div>

            <Button
              value='Add Property'
              variant='primary'
              icon='+'
              size='big'
            />
          </div>
        )}
      </div>

      <div className='tenants-bar'>
        <div className='tenants-bar-left'>
          <DropDownButton value={'All Properties'} icon={DownButton} />
          <div className='margin-right-1'></div>
          <DropDownButton value={'Current & Past'} icon={DownButton} />
        </div>
        <div className='tenants-bar-right'>
          {filterActive && filterActive === 1
            ? `${TENANTS_TABLE_DATA.length} Total`
            : `${PROSPECTS_TABLE_DATA.length} Total`}
        </div>
      </div>

      {filterActive && filterActive === 1 ? (
        <div className='tenants-table'>
          <TenantsTable
            tableHead={TENANTS_TABLE_HEAD}
            tableColumns={TENANTS_TABLE_DATA}
          />
        </div>
      ) : (
        <div className='Prospects'>
          <ProspectsTable
            tableHead={PROSPECTS_TABLE_HEAD}
            tableColumns={PROSPECTS_TABLE_DATA}
          />
        </div>
      )}
    </Layout>
  )
}

export default TenantsPage
