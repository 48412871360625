import React from 'react'

import './FilterTabs.css'

const FilterTabs = ({ filterArray, filterActive, setFilterActive }) => {
  return (
    <ul className='filter-list'>
      {filterArray.map((item) => (
        <li
          key={item.id}
          className={
            filterActive === item.id
              ? 'filter-item filter-active'
              : 'filter-item'
          }
          onClick={() => setFilterActive(item.id)}
        >
          {item.value}
        </li>
      ))}
    </ul>
  )
}

export default FilterTabs
