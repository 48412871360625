import React from 'react'
import { Link, withRouter } from 'react-router-dom'

import Logo from '../../assets/icons/logo/home.svg'
import Dashboard from '../../assets/icons/sidebar/dashboard.svg'
import Chat from '../../assets/icons/sidebar/chat.svg'
import Contacts from '../../assets/icons/sidebar/contacts.svg'
import Deals from '../../assets/icons/sidebar/deals.svg'
import DollarSign from '../../assets/icons/sidebar/dollar-sign.svg'
import Settings from '../../assets/icons/sidebar/Vector.svg'
import TasksActive from '../../assets/icons/sidebar/tasks-active.svg'
import Task from '../../assets/icons/sidebar/task.svg'

import './Sidebar.css'

const Sidebar = (props) => {
	return (
		<div className='sidebar'>
			<div className='sidebar-logo'>
				<img src={Logo} alt='logo-icon' className='logo-icon' />
				<Link className='logo' to='/dashboard'>
					PropertEase
				</Link>
			</div>

			<div className='sidebar-menu'>
				<ul className='sidebar-links'>
					<li className='sidebar-links-item'>
						<Link to='/' className='sidebar-links-item-link'>
							<img
								src={Dashboard}
								alt='dashboard-icon'
								className='sidebar-links-item-icon'
							/>
							<div className='sidebar-links-item-name'>Dashboard</div>
						</Link>
					</li>

					<li
						className={
							props.location.pathname === '/properties'
								? 'sidebar-links-item link-active'
								: 'sidebar-links-item'
						}
					>
						{props.location.pathname === '/properties' ? (
							<>
								<Link
									to='/properties'
									className='sidebar-links-item-link-active'
								>
									<img
										src={TasksActive}
										alt='dashboard-icon'
										className='sidebar-links-item-icon'
									/>
									<div className='sidebar-links-item-name'>Properties</div>
								</Link>
							</>
						) : (
							<Link to='/properties' className='sidebar-links-item-link'>
								<img
									src={Task}
									alt='dashboard-icon'
									className='sidebar-links-item-icon'
								/>
								<div className='sidebar-links-item-name'>Properties</div>
							</Link>
						)}
					</li>

					{props.location.pathname === '/properties' ? (
						<ul className='sub-menu-from-properties'>
							<li className='sub-menu-from-properties-items'>
								<Link to='#' className='sub-menu-from-properties-items-link'>
									213- 216 Bloomfield
								</Link>
							</li>
							<li className='sub-menu-from-properties-items'>
								<Link to='#' className='sub-menu-from-properties-items-link'>
									125 PropertEase Blvd
								</Link>
							</li>
							<li className='sub-menu-from-properties-items'>
								<Link to='#' className='sub-menu-from-properties-items-link'>
									930 East County Line
								</Link>
							</li>
							<li className='sub-menu-from-properties-items'>
								<Link to='#' className='sub-menu-from-properties-items-link'>
									211 Summer Ave
								</Link>
							</li>
						</ul>
					) : undefined}

					<li className='sidebar-links-item'>
						<Link to='#' className='sidebar-links-item-link'>
							<img
								src={DollarSign}
								alt='dashboard-icon'
								className='sidebar-links-item-icon'
							/>
							<div className='sidebar-links-item-name'>Accounting</div>
						</Link>
					</li>

					<li className='sidebar-links-item'>
						<Link to='#' className='sidebar-links-item-link'>
							<img
								src={Contacts}
								alt='dashboard-icon'
								className='sidebar-links-item-icon'
							/>
							<div className='sidebar-links-item-name'>Maintanance</div>
						</Link>
					</li>

					<li className='sidebar-links-item'>
						<Link to='#' className='sidebar-links-item-link'>
							<img
								src={Chat}
								alt='dashboard-icon'
								className='sidebar-links-item-icon'
							/>
							<div className='sidebar-links-item-name'>Vendors</div>
						</Link>
					</li>

					<li className='sidebar-links-item'>
						<Link to='#' className='sidebar-links-item-link'>
							<img
								src={Deals}
								alt='dashboard-icon'
								className='sidebar-links-item-icon'
							/>
							<div className='sidebar-links-item-name'>Reports</div>
						</Link>
					</li>
				</ul>

				<ul className='sidebar-links-second'>
					<li className='sidebar-links-item'>
						<Link to='#' className='sidebar-links-item-link'>
							<img
								src={Settings}
								alt='dashboard-icon'
								className='sidebar-links-item-icon'
							/>
							<div className='sidebar-links-item-name'>Settings</div>
						</Link>
					</li>
				</ul>
			</div>
		</div>
	)
}

export default withRouter(Sidebar)
