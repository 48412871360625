export const TENANTS_TABLE_HEAD = [
  { id: 1, value: '' },
  { id: 2, value: 'Name' },
  { id: 3, value: 'Property' },
  { id: 4, value: 'Unit' },
  { id: 5, value: 'Balance' },
  { id: 6, value: 'Email' },
  { id: 7, value: 'Phone' },
  { id: 8, value: 'Tenancy' },
  { id: 9, value: 'Lease Status' },
  { id: 10, value: '' },
]

export const TENANTS_TABLE_DATA = [
  {
    id: 1,
    name: 'Jose Morel',
    property: '211 Summer Avenue',
    unit: '1A',
    balance: '$3580',
    email: 'eric@entechboiler.com',
    phone: '(732)730-1595',
    tenancy: 'Current',
    leaseStatus: 'Active',
    action: 'edit',
  },
  {
    id: 2,
    name: 'Brittany Barnes',
    property: '216-218 Bloomfield Ave',
    unit: '2B',
    balance: '$980',
    email: '',
    phone: '(732)730-1595',
    tenancy: 'Current',
    leaseStatus: 'Active',
    action: 'edit',
  },
  {
    id: 3,
    name: 'Mercedes Rojas',
    property: '216-218 Bloomfield Ave',
    unit: '3C',
    balance: '$5,580',
    email: '',
    phone: '(732)730-1595',
    tenancy: 'Current',
    leaseStatus: 'Expiring',
    action: 'edit',
  },
  {
    id: 4,
    name: 'Edward Alleyne',
    property: '216-218 Bloomfield Ave',
    unit: '3C',
    balance: '$1580',
    email: 'heather@entechboiler.com',
    phone: '',
    tenancy: 'Current',
    leaseStatus: 'Active',
    action: 'edit',
  },
  {
    id: 5,
    name: 'Andrea Marie Mikolay',
    property: '211 Summer Avenue',
    unit: '3C',
    balance: '$0',
    email: 'goldie@entechboiler.com',
    phone: '',
    tenancy: 'Current',
    leaseStatus: 'Active',
    action: 'edit',
  },
  {
    id: 6,
    name: 'Jose Morel',
    property: '211 Summer Avenue',
    unit: '1A',
    balance: '$3580',
    email: 'eric@entechboiler.com',
    phone: '(732)730-1595',
    tenancy: 'Current',
    leaseStatus: 'Active',
    action: 'edit',
  },
  {
    id: 7,
    name: 'Brittany Barnes',
    property: '216-218 Bloomfield Ave',
    unit: '2B',
    balance: '$980',
    email: '',
    phone: '(732)730-1595',
    tenancy: 'Current',
    leaseStatus: 'Active',
    action: 'edit',
  },
  {
    id: 8,
    name: 'Mercedes Rojas',
    property: '216-218 Bloomfield Ave',
    unit: '3C',
    balance: '$5,580',
    email: '',
    phone: '(732)730-1595',
    tenancy: 'Current',
    leaseStatus: 'Expired',
    action: 'edit',
  },
  {
    id: 9,
    name: 'Edward Alleyne',
    property: '216-218 Bloomfield Ave',
    unit: '3C',
    balance: '$1580',
    email: 'heather@entechboiler.com',
    phone: '',
    tenancy: 'Current',
    leaseStatus: 'Expiring',
    action: 'edit',
  },
  {
    id: 10,
    name: 'Andrea Marie Mikolay',
    property: '211 Summer Avenue',
    unit: '3C',
    balance: '$0',
    email: 'goldie@entechboiler.com',
    phone: '',
    tenancy: 'Current',
    leaseStatus: 'Expiring',
    action: 'edit',
  },
  {
    id: 11,
    name: 'Jose Morel',
    property: '211 Summer Avenue',
    unit: '1A',
    balance: '$3580',
    email: 'eric@entechboiler.com',
    phone: '(732)730-1595',
    tenancy: 'Current',
    leaseStatus: 'Active',
    action: 'edit',
  },
  {
    id: 12,
    name: 'Brittany Barnes',
    property: '216-218 Bloomfield Ave',
    unit: '2B',
    balance: '$980',
    email: '',
    phone: '(732)730-1595',
    tenancy: 'Current',
    leaseStatus: 'Active',
    action: 'edit',
  },
  {
    id: 13,
    name: 'Mercedes Rojas',
    property: '216-218 Bloomfield Ave',
    unit: '3C',
    balance: '$5,580',
    email: '',
    phone: '(732)730-1595',
    tenancy: 'Current',
    leaseStatus: 'Expiring',
    action: 'edit',
  },
  {
    id: 14,
    name: 'Edward Alleyne',
    property: '216-218 Bloomfield Ave',
    unit: '3C',
    balance: '$1580',
    email: 'heather@entechboiler.com',
    phone: '',
    tenancy: 'Current',
    leaseStatus: 'Active',
    action: 'edit',
  },
  {
    id: 15,
    name: 'Andrea Marie Mikolay',
    property: '211 Summer Avenue',
    unit: '3C',
    balance: '$0',
    email: 'goldie@entechboiler.com',
    phone: '',
    tenancy: 'Current',
    leaseStatus: 'Active',
    action: 'edit',
  },
  {
    id: 16,
    name: 'Jose Morel',
    property: '211 Summer Avenue',
    unit: '1A',
    balance: '$3580',
    email: 'eric@entechboiler.com',
    phone: '(732)730-1595',
    tenancy: 'Current',
    leaseStatus: 'Active',
    action: 'edit',
  },
  {
    id: 17,
    name: 'Brittany Barnes',
    property: '216-218 Bloomfield Ave',
    unit: '2B',
    balance: '$980',
    email: '',
    phone: '(732)730-1595',
    tenancy: 'Current',
    leaseStatus: 'Active',
    action: 'edit',
  },
  {
    id: 18,
    name: 'Mercedes Rojas',
    property: '216-218 Bloomfield Ave',
    unit: '3C',
    balance: '$5,580',
    email: '',
    phone: '(732)730-1595',
    tenancy: 'Current',
    leaseStatus: 'Expired',
    action: 'edit',
  },
  {
    id: 19,
    name: 'Edward Alleyne',
    property: '216-218 Bloomfield Ave',
    unit: '3C',
    balance: '$1580',
    email: 'heather@entechboiler.com',
    phone: '',
    tenancy: 'Current',
    leaseStatus: 'Expiring',
    action: 'edit',
  },
  {
    id: 20,
    name: 'Andrea Marie Mikolay',
    property: '211 Summer Avenue',
    unit: '3C',
    balance: '$0',
    email: 'goldie@entechboiler.com',
    phone: '',
    tenancy: 'Current',
    leaseStatus: 'Expiring',
    action: 'edit',
  },
]
