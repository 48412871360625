import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import Layout from '../../../components/Layout/Layout'

import LeftIcon from '../../../assets/icons/tenants/summary/left.svg'
import RightIcon from '../../../assets/icons/tenants/summary/right.svg'
import EditIcon from '../../../assets/icons/tenants/summary/edit-icon-blue.svg'

import FilterMenu from '../../../components/FilterMenu/FilterMenu'
import {
  FILTER_MENU_ITEMS,
  TENANT_SUMMARY_PERSON_DATA,
} from '../../../services/TenantsSummaryData'

import './TenantSummary.css'

const TenantSummary = () => {
  const [activeFilterMenu, setFilterMenu] = useState(1)

  return (
    <Layout>
      <div className='tenant-summary-top'>
        <div className='left-link'>
          <img src={LeftIcon} alt='' />
          <Link to='/tenants' className='summary-link'>
            Back to tenants
          </Link>
        </div>

        <div className='right-link'>
          <Link to='/tenants' className='summary-link'>
            Next Tenants
          </Link>
          <img src={RightIcon} alt='' />
        </div>
      </div>

      <div className='tenant-summary-main'>
        <div className='tenant-summary-left'>
          <div className='profile'>
            <div className='profile-pic'>
              {TENANT_SUMMARY_PERSON_DATA.profilePic}
            </div>
            <div className='profile-details'>
              <div className='name'>{TENANT_SUMMARY_PERSON_DATA.name}</div>
              <div className='type'>Tenant</div>
              <div className='address'>
                {TENANT_SUMMARY_PERSON_DATA.address}
              </div>
            </div>
          </div>

          <div className='column-filter-menu'>
            <FilterMenu
              menuItems={FILTER_MENU_ITEMS}
              active={activeFilterMenu}
              set={setFilterMenu}
            />
          </div>
        </div>

        <div className='tenant-summary-right'>
          <div className='left-half'>
            <div className='contact-information'>
              <div className='mini-top-section'>
                <div className='mini-section-title color-primary'>
                  Contact Information
                </div>
                <div className='mini-section-edit color-primary'>
                  <img src={EditIcon} alt='min-section-icon' />
                  <span>Edit</span>
                </div>
              </div>

              <div className='mini-middle-section'>
                <div className='mini-section-left'>
                  <div className='item-title'>Name</div>
                  <div className='item-value'>
                    {TENANT_SUMMARY_PERSON_DATA.name}
                  </div>
                </div>

                <div className='mini-section-right'>
                  <div className='item-title'>Email</div>
                  <div className='item-value'>
                    {TENANT_SUMMARY_PERSON_DATA.email}
                  </div>
                  <div className='item-value color-primary text-underline'>
                    {TENANT_SUMMARY_PERSON_DATA.email2}
                  </div>
                </div>
              </div>

              <div className='mini-lower-section'>
                <div className='mini-section-left'>
                  <div className='item-title'>Phone Number</div>
                  <div className='item-value'>
                    M: {TENANT_SUMMARY_PERSON_DATA.phoneM} <br />
                    H: {TENANT_SUMMARY_PERSON_DATA.phoneH} <br />
                    W: {TENANT_SUMMARY_PERSON_DATA.phoneW}
                  </div>
                </div>

                <div className='mini-section-right'>
                  <div className='item-title'>Address</div>
                  <div className='item-value'>
                    {TENANT_SUMMARY_PERSON_DATA.houseAddress} <br />
                    {TENANT_SUMMARY_PERSON_DATA.townAddress}
                  </div>
                </div>
              </div>
            </div>

            <div className='personal-information'>
              <div className='mini-top-section'>
                <div className='mini-section-title color-primary'>
                  Personal Information
                </div>
                <div className='mini-section-edit color-primary'>
                  <img src={EditIcon} alt='min-section-icon' />
                  <span>Edit</span>
                </div>
              </div>
              <div className='mini-middle-section'>
                <div className='mini-section-left'>
                  <div className='item-title'>Date of Birth</div>
                  <div className='item-value'>
                    {TENANT_SUMMARY_PERSON_DATA.dateOfBirth}
                  </div>
                </div>

                <div className='mini-section-right'>
                  <div className='item-title'>Social Security Number</div>
                  <div className='item-value'>
                    {TENANT_SUMMARY_PERSON_DATA.socialSecurityNumber}
                  </div>
                </div>
              </div>
              <div className='mini-lower-section'>
                <div className='mini-section-left'>
                  <div className='item-title'>Occupation</div>
                  <div className='item-value'>
                    M: {TENANT_SUMMARY_PERSON_DATA.occupation}
                  </div>
                </div>

                <div className='mini-section-right'>
                  <div className='item-title'>Employer</div>
                  <div className='item-value'>
                    {TENANT_SUMMARY_PERSON_DATA.employer}
                  </div>
                </div>
              </div>
            </div>
            <div className='emergency-contact'>
              <div className='mini-top-section'>
                <div className='mini-section-title color-primary'>
                  Emergency Contact
                </div>
                <div className='mini-section-edit color-primary'>
                  <img src={EditIcon} alt='min-section-icon' />
                  <span>Edit</span>
                </div>
              </div>
              <div className='mini-middle-section'>
                <div className='mini-section-left'>
                  <div className='item-title'>Contact</div>
                  <div className='item-value'>
                    {TENANT_SUMMARY_PERSON_DATA.name} <br />
                    {TENANT_SUMMARY_PERSON_DATA.phoneM} <br />
                    {TENANT_SUMMARY_PERSON_DATA.email}
                  </div>
                </div>
              </div>
            </div>

            <div className='notes'>
              <div className='note-title color-primary'>Notes</div>
              <div className='note-card'></div>
            </div>
          </div>
          <div className='right-half'>
            <div className='lease-details-card'></div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default TenantSummary
