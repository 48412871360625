import React from 'react'

import './Button.css'

const Button = ({ value, variant, icon, size }) => {
  return (
    <button className={`btn btn-${variant} btn-${size}`}>
      {icon ? <span className='btn-icon'>{icon}</span> : undefined}
      {value}
    </button>
  )
}

export default Button
