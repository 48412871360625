import React from 'react'

import './DropDownButton.css'

const DropDownButton = ({ value, icon }) => {
  return (
    <div className='btn-dropdown'>
      <div className='value'>{value}</div>

      {icon && <img className='btn-dropdown-icon' src={icon} alt={value} />}
    </div>
  )
}

export default DropDownButton
