import React from 'react'

import './FilterMenu.css'

const FilterMenu = ({ menuItems, active, set }) => {
  return (
    <div className='filter-menu'>
      {menuItems.map((item) => (
        <div
          className={
            active === item.id ? 'filter-menu-item active' : 'filter-menu-item'
          }
          key={item.id}
          onClick={() => set(item.id)}
        >
          <div className='filter-menu-icon'>
            <img src={item.icon} alt={item.title} />
          </div>
          <div className='filter-menu-details'>
            <div className='filter-menu-title'>{item.title}</div>
            <div className='filter-menu-subtitle'>{item.subTitle}</div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default FilterMenu
