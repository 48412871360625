import React from 'react'
import Sidebar from '../Sidebar/Sidebar'
import Navbar from '../Navbar/Navbar'

import './Layout.css'

const Layout = (props) => {
	return (
		<div className='react-root'>
			<div className='left-side'>
				<Sidebar />
			</div>
			<div className='right-side'>
				<div className='navbar'>
					<Navbar />
				</div>
				<div className='main-content'>{props.children}</div>
			</div>
		</div>
	)
}

export default Layout
