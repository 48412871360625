import React from 'react'

import HomeBlue from '../../assets/icons/PropertiesPage/homeBlue.svg'
import HomeGreen from '../../assets/icons/PropertiesPage/homeGreen.svg'
import HomeRed from '../../assets/icons/PropertiesPage/homeRed.svg'
import EditIcon from '../../assets/icons/PropertiesPage/edit.svg'
import RightArrow from '../../assets/icons/PropertiesPage/rightArrow.svg'

import './PropertiesCard.css'

const PropertiesCard = ({
	name,
	address,
	totalUnit,
	occupiedUnit,
	vacantUnit,
	occupiedPercentage,
}) => {
	return (
		<div className='properties-card'>
			<div className='properties-card-header'>
				<div className='properties-card-name'>{name}</div>
				<div className='properties-card-address'>{address}</div>
			</div>

			<div className='properties-card-body'>
				<ul className='properties-card-detail-section'>
					<li className='properties-card-detail-item'>
						<div className='properties-card-detail-item-icon'>
							<img src={HomeBlue} alt='Homeblue' />
						</div>
						<div className='properties-card-detail-item-number'>
							{totalUnit}
						</div>
						<div className='properties-card-detail-item-details'>Units</div>
					</li>
					<li className='properties-card-detail-item'>
						<div className='properties-card-detail-item-icon'>
							<img src={HomeGreen} alt='HomeGreen' />
						</div>
						<div className='properties-card-detail-item-number'>
							{occupiedUnit}
						</div>
						<div className='properties-card-detail-item-details'>
							Occupied Units
						</div>
					</li>
					<li className='properties-card-detail-item'>
						<div className='properties-card-detail-item-icon'>
							<img src={HomeRed} alt='HomeRed' />
						</div>
						<div className='properties-card-detail-item-number'>
							{vacantUnit}
						</div>
						<div className='properties-card-detail-item-details'>
							Vacant Units
						</div>
					</li>
				</ul>
			</div>

			<div className='properties-card-occupied'>
				This Property is{' '}
				<span className='color-primary'>{occupiedPercentage}%</span> Occupied
			</div>

			<div className='properties-card-footer'>
				<div className='properties-card-edit-button'>
					<div>Edit Property</div>
					<img
						src={EditIcon}
						alt='editIcon'
						className='properties-card-button-icon'
					/>
				</div>
				<div className='properties-card-view-more-button'>
					<div>View More</div>
					<img
						src={RightArrow}
						alt='editIcon'
						className='properties-card-button-icon'
					/>
				</div>
			</div>
		</div>
	)
}

export default PropertiesCard
